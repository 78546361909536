<script>
import { mapActions, mapGetters } from 'vuex';
import UserModal from '@/views/dashboard/components/modals/UserModal';
import helper from '@/plugins/helper';
import USER_STATUS from '@/constants/user_statuses';

export default {
    name: 'Users',
    components: { UserModal },
    data: () => ({
        loading: false,
        loadingExcel: false,
        menu: false,
        filter: {
            mail: '',
            user_id: '',
            first_name: '',
            last_name: '',
            role: '',
            status: '',
            sales_name: '',
            date: [],
        },
        roles: [
            { key: '', value: 'All' },
            { key: 'admin', value: 'Admin' },
            { key: 'investor', value: 'Investor' },
            { key: 'entrepreneur', value: 'Founder' },
        ],
    }),
    computed: {
        headers() {
            return [
                {
                    text: 'User id',
                    value: 'user_id',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.user_id.toLowerCase());
                    },
                    align: 'left',
                },
                {
                    text: 'Mail',
                    value: 'mail',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.mail.toLowerCase());
                    },
                    align: 'left',
                },
                {
                    text: 'First name',
                    value: 'first_name',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.first_name.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Last name',
                    value: 'last_name',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.last_name.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Role',
                    value: 'role',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.role.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Register date',
                    value: 'created_at_human',
                    filter: (value, search, item) => {
                        const THREE_HOURS_IN_SECONDS = 3 * 3600;
                        const DAY_IN_SECONDS = 86399;

                        if (this.filter.date.length === 2) {
                            let startDateTimestamp = helper.toTimestamp(
                                this.filter.date['0'],
                            );
                            let endDateTimestamp = helper.toTimestamp(
                                this.filter.date['1'],
                            );

                            if (startDateTimestamp > endDateTimestamp) {
                                [startDateTimestamp, endDateTimestamp] = [
                                    endDateTimestamp,
                                    startDateTimestamp,
                                ];
                            }
                            return (
                                startDateTimestamp - THREE_HOURS_IN_SECONDS <=
                                    item.created_at &&
                                endDateTimestamp +
                                    DAY_IN_SECONDS -
                                    THREE_HOURS_IN_SECONDS >=
                                    item.created_at
                            );
                        }

                        if (this.filter.date.length === 1) {
                            let startDateTimestamp = helper.toTimestamp(
                                this.filter.date['0'],
                            );
                            let endDateTimestamp =
                                startDateTimestamp + DAY_IN_SECONDS;

                            return (
                                startDateTimestamp - THREE_HOURS_IN_SECONDS <=
                                    item.created_at &&
                                endDateTimestamp - THREE_HOURS_IN_SECONDS >=
                                    item.created_at
                            );
                        }

                        return true;
                    },
                    align: 'center',
                },
                {
                    text: 'Sales Manager',
                    value: 'sales_name',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.sales_name.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Status',
                    value: 'status_name',
                    filter: (value, search, item) => {
                        if (this.filter.status.length > 0) {
                            return this.filter.status.includes(
                                Number.parseInt(item.status),
                            );
                        }
                        return true;
                    },
                    align: 'center',
                },
                {
                    text: 'Action',
                    value: 'button',
                    align: 'center',
                    sortable: false,
                },
            ];
        },

        ...mapGetters('users', {
            users: 'getUsers',
            statuses: 'getUserStatuses',
        }),
    },
    methods: {
        ...mapActions('users', [
            'fetchUsers',
            'fetchUserStatuses',
            'getAllUsersExcel',
        ]),
        ...mapActions('user_modal', ['fetchUserInfo']),

        resetDate() {
            this.filter.date = [];
            this.menu = false;
        },

        roleAdapter(role) {
            return role === 'entrepreneur' ? 'founder' : role;
        },

        async downloadUsersExcel() {
            this.loadingExcel = true;

            await this.getAllUsersExcel().then(res => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'all-users.xlsx');
                document.body.appendChild(link);
                link.click();
            });
            this.loadingExcel = false;
        },

        dataTime(value) {
            const time = +value;
            if (!time || typeof time === 'string') return '0';
            const formatter = new Intl.DateTimeFormat('en-AU', {
                timeStyle: 'medium',
                hour12: false,
            });
            return formatter.format(new Date(+time * 1000));
        },

        dataDayYear(value) {
            const time = +value;
            if (!time || typeof time === 'string') return '0';
            const formatter = new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            return formatter.format(new Date(+time * 1000));
        },

        getColorByStatus(status) {
            let color;
            switch (parseInt(status)) {
                case USER_STATUS.APPROVED:
                    color = 'green';
                    break;
                case USER_STATUS.PENDING:
                    color = 'orange';
                    break;
                case USER_STATUS.REPEAT_PENDING:
                    color = 'orange';
                    break;
                case USER_STATUS.DECLINE:
                    color = 'red';
                    break;
                default:
                    color = null;
                    break;
            }
            return color;
        },
    },

    async created() {
        this.loading = true;
        if (this.$route.query.status_1) {
            this.filter.status = [
                parseInt(this.$route.query.status_1),
                parseInt(this.$route.query.status_2),
            ];
        }
        await this.fetchUsers();
        await this.fetchUserStatuses();
        this.loading = false;
    },
};
</script>

<template>
    <div>
        <div class="startup__actions">
            <v-btn
                class="btnDownload"
                color="#47a4db"
                :loading="loadingExcel"
                @click="downloadUsersExcel"
            >
                Download Excel
            </v-btn>
        </div>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            dense
            :headers="headers"
            :items="users"
            :items-per-page="10"
            :footer-props="{
                'items-per-page-options': [10, 50, 100, 150, 200],
            }"
        >
            <template v-slot:[`item.created_at`]="{ item }">
                <span style="margin-right: 10px">
                    {{ dataDayYear(item.created_at) }}
                </span>
                {{ dataTime(item.created_at) }}
            </template>

            <template v-slot:[`item.mail`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            v-on="on"
                            v-bind="attrs"
                            class="blue--text"
                            @click.prevent="fetchUserInfo(item.user_id)"
                            >{{ item.mail }}</a
                        >
                    </template>
                    <span>View user`s profile</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.sales_name`]="{ item }">
                {{ item.sales_name ? item.sales_name : '-' }}
            </template>

            <template v-slot:[`item.status_name`]="{ item }">
                <v-chip small dark :color="getColorByStatus(item.status)">
                    {{ item.status_name }}
                </v-chip>
            </template>

            <template v-slot:[`item.role`]="{ item }">
                {{ roleAdapter(item.role) }}
            </template>

            <template v-slot:[`item.button`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-on="on"
                            v-bind="attrs"
                            icon
                            @click.prevent="fetchUserInfo(item.user_id)"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>View/Edit profile</span>
                </v-tooltip>
            </template>

            <template v-slot:[`body.prepend`]>
                <tr>
                    <td>
                        <v-text-field
                            v-model="filter.user_id"
                            type="integer"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-text-field
                            v-model="filter.mail"
                            type="text"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-text-field
                            v-model="filter.first_name"
                            type="text"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-text-field
                            v-model="filter.last_name"
                            type="text"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-select
                            v-model="filter.role"
                            :items="roles"
                            type="text"
                            item-value="key"
                            item-text="value"
                        ></v-select>
                    </td>
                    <td>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="filter.date"
                                    label="Date range"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.date" no-title range>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="resetDate">
                                    Clear
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </td>
                    <td>
                        <v-text-field
                            v-model="filter.sales_name"
                            type="text"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-select
                            class="status_select"
                            v-model="filter.status"
                            type="text"
                            :items="statuses"
                            item-text="name"
                            item-value="id"
                            name="name"
                            multiple
                        >
                        </v-select>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <user-modal />
    </div>
</template>
<style>
.status_select .v-select__selections {
    height: 34px;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status_select {
    max-width: 200px;
}

.startup__actions {
    top: -60px;
    right: 5%;
    z-index: 1;
    position: absolute;
}

.btnDownload {
    cursor: pointer;
}
</style>
